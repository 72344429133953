



































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import AppService from '@common-src/service3/app';
import { AppEntityModel } from '@common-src/entity-model/app-entity';
import { getPermissionApp } from '@common-src/service3/common.service';

@Component
export default class AppSelectComponent extends BaseComponent {
    appList: Array<any> = null;
    created() {
        // AppService.getAllApp().then(res => {
        //     this.appList = res;
        // });
        getPermissionApp().then(res => {
            this.appList = res;
        });
    }
    getOpenUrl(appModel) {
        return `/web/${appModel.identifier}/`;
    }
    async selectAppClick(appModel: AppEntityModel) {
        const openUrl = this.getOpenUrl(appModel);
        if (openUrl) {
            window.open(openUrl);
        } else {
            this.showMessageWarning('站点配置错误，请联系管理员');
        }
    }
}

