var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-select-component" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-breadcrumb" },
        [
          _c(
            "a-breadcrumb",
            [
              _c(
                "a-breadcrumb-item",
                [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                1
              ),
              _c("a-breadcrumb-item", [_vm._v("子系统")]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "page-body page-body-margin" },
      [
        _vm.appList && _vm.appList.length > 0
          ? _c(
              "div",
              { staticClass: "group-item" },
              _vm._l(_vm.appList, function (group) {
                return _c(
                  "div",
                  { key: group.id, staticClass: "app-item" },
                  [
                    !(_vm.appList.length === 1 && !group.id)
                      ? _c("div", { staticClass: "group-title" }, [
                          _vm._v(_vm._s(group.name)),
                        ])
                      : _vm._e(),
                    _vm._l(group.appList, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "project-item-wrapper",
                          on: {
                            click: function ($event) {
                              return _vm.selectAppClick(item)
                            },
                          },
                        },
                        [
                          _c(
                            "a-card",
                            {
                              staticClass: "project-item-card",
                              attrs: { hoverable: "" },
                            },
                            [
                              _c("div", { staticClass: "flex" }, [
                                _c("img", {
                                  staticClass: "project-item-img",
                                  attrs: { src: item.icon },
                                }),
                                _c("div", { staticClass: "app-text-wrapper" }, [
                                  _c("div", { staticClass: "app-title" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c("span", { staticClass: "version-text" }, [
                                    _vm._v(_vm._s(item.version)),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "secondary-color" },
                                    [_vm._v(_vm._s(item.remark))]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
              0
            )
          : _c("a-empty"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }